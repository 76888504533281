<template>
  <section class="banner counter-wrapper">
    <div class="banner__counter">
      <div class="counter-col">
        <span class="counter">{{$t('32,000+')}}</span>
        <h6>{{$t('Orders fulfilled')}}</h6>
      </div>
      <div class="counter-col">
        <span class="counter">{{$t('45+')}}</span>
        <h6>{{$t('Years in business')}}</h6>
      </div>
      <div class="counter-col">
        <span class="counter">{{$t('1,800+')}}</span>
        <h6>{{$t('Operators')}}</h6>
      </div>
      <div class="counter-col">
        <span class="counter">{{$t('3,000+')}}</span>
        <h6>{{$t('Machinery in stock')}}</h6>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'CounterComponent'
}
</script>
